import './index.scss'
import { Link } from 'react-router-dom'

function ProjectCard({ title, tagline, cover, color, site, github, icons, id, url, details, website }) {

    return(
        <div className="card" style={{backgroundColor: color}} key={`card${id}`}>
            <img alt="A screenshot of the website" src={cover} key={`photo${id}`} />
            <div className="project-details" key={`details${id}`}>
               <h3>{title}</h3>
               <div className='description-container'>
                    <p>{tagline}</p>
               </div>
               <div className='icon-container'>
                { icons.map((iconSource, index) => (
                    <img alt='' src={iconSource} key={`icon${index}`}/>
                ))}
               </div>
               {/* { title === "Bivo" ? (
                <button className='disabled' disabled>Coming soon !</button>
               ) : ( */}
                <div className='button-container'>
                    { github && (
                    <a href={github} target='blank'>
                        <button>
                            Repo Github
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233.67 233.7">
                                <g id="Layer_1-2" data-name="Layer 1">
                                <path className="cls-1" d="M101.04,233.64c-19.09,0-38.18.06-57.27-.01C18.26,233.53.14,215.52.08,190.04c-.09-38.18-.11-76.36,0-114.55.07-25.49,18.17-43.45,43.7-43.58,13.11-.07,26.23-.05,39.34,0,7.69.03,12.45,4.07,12.5,10.46.05,6.51-4.98,10.73-12.83,10.73-12.95,0-25.9-.07-38.84,0-13.97.08-22.66,8.79-22.67,22.76-.04,38.18-.04,76.36-.01,114.54,0,12.91,9.02,22.01,21.9,22.03,38.51.05,77.02.05,115.54,0,12.86-.02,21.81-9.04,21.9-22.05.08-13.61-.07-27.23.03-40.84.06-7.55,5.75-12.5,12.55-11.22,5.03.95,8.5,4.73,8.54,10.12.11,15.44.49,30.9-.18,46.31-.93,21.36-19.29,38.33-41.21,38.82-9.96.22-19.92.06-29.88.06-9.79,0-19.59,0-29.38,0Z"/>
                                <path className="cls-1" d="M195.88,21.2c-18.91,0-37.82.03-56.73-.02-6.5-.02-10.98-3.74-11.53-9.32-.66-6.7,3.85-11.72,11-11.79,13.1-.13,26.21-.04,39.32-.04,14.43,0,28.87-.04,43.3.01,8.66.03,12.39,3.78,12.41,12.48.04,27.04.03,54.08,0,81.12-.01,7.87-3.83,12.35-10.4,12.45-6.73.1-10.75-4.58-10.76-12.63-.02-18.56,0-37.13,0-57.02-2.18,2.09-3.65,3.45-5.07,4.86-26.87,26.86-53.73,53.73-80.6,80.58-1.29,1.29-2.55,2.65-4.03,3.68-4.12,2.87-9.7,2.34-13.27-1.11-3.73-3.59-4.37-9.37-1.36-13.63,1.04-1.48,2.41-2.73,3.7-4.02,26.39-26.4,52.78-52.8,79.19-79.17,1.83-1.83,3.86-3.45,5.8-5.16-.32-.42-.64-.85-.97-1.27Z"/>
                                </g>
                            </svg>
                        </button>
                    </a>
                    )}
                    { site && (
                    <a href={site} target='blank'>
                        <button>
                            {website}
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233.67 233.7">
                                <g id="Layer_1-2" data-name="Layer 1">
                                <path className="cls-1" d="M101.04,233.64c-19.09,0-38.18.06-57.27-.01C18.26,233.53.14,215.52.08,190.04c-.09-38.18-.11-76.36,0-114.55.07-25.49,18.17-43.45,43.7-43.58,13.11-.07,26.23-.05,39.34,0,7.69.03,12.45,4.07,12.5,10.46.05,6.51-4.98,10.73-12.83,10.73-12.95,0-25.9-.07-38.84,0-13.97.08-22.66,8.79-22.67,22.76-.04,38.18-.04,76.36-.01,114.54,0,12.91,9.02,22.01,21.9,22.03,38.51.05,77.02.05,115.54,0,12.86-.02,21.81-9.04,21.9-22.05.08-13.61-.07-27.23.03-40.84.06-7.55,5.75-12.5,12.55-11.22,5.03.95,8.5,4.73,8.54,10.12.11,15.44.49,30.9-.18,46.31-.93,21.36-19.29,38.33-41.21,38.82-9.96.22-19.92.06-29.88.06-9.79,0-19.59,0-29.38,0Z"/>
                                <path className="cls-1" d="M195.88,21.2c-18.91,0-37.82.03-56.73-.02-6.5-.02-10.98-3.74-11.53-9.32-.66-6.7,3.85-11.72,11-11.79,13.1-.13,26.21-.04,39.32-.04,14.43,0,28.87-.04,43.3.01,8.66.03,12.39,3.78,12.41,12.48.04,27.04.03,54.08,0,81.12-.01,7.87-3.83,12.35-10.4,12.45-6.73.1-10.75-4.58-10.76-12.63-.02-18.56,0-37.13,0-57.02-2.18,2.09-3.65,3.45-5.07,4.86-26.87,26.86-53.73,53.73-80.6,80.58-1.29,1.29-2.55,2.65-4.03,3.68-4.12,2.87-9.7,2.34-13.27-1.11-3.73-3.59-4.37-9.37-1.36-13.63,1.04-1.48,2.41-2.73,3.7-4.02,26.39-26.4,52.78-52.8,79.19-79.17,1.83-1.83,3.86-3.45,5.8-5.16-.32-.42-.64-.85-.97-1.27Z"/>
                                </g>
                            </svg>
                        </button>
                    </a>
                    )}
                </div>
                <div>
                    <Link to={`/project/${url}`}>
                        <button className='see-details'>
                            {details}
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269.95 183.82">
                                <g id="Layer_1-2" data-name="Layer 1">
                                    <path className="cls-1" d="M230.58,78.96c-4.8-4.5-9.7-8.9-14.37-13.53-15.25-15.13-30.37-30.39-45.63-45.52-3.69-3.66-5.11-7.87-3.45-12.7,1.44-4.21,4.62-7.48,9.2-7.2,3.38.2,7.53,1.34,9.83,3.6,26.7,26.28,53.15,52.82,79.59,79.38,5.68,5.71,5.58,11.89-.07,17.55-26.34,26.42-52.7,52.81-79.11,79.16-5.28,5.27-12.37,5.45-16.88.69-4.65-4.9-4.25-11.53,1.05-16.82,19.8-19.76,39.61-39.5,60.62-60.45-3.2,0-5.04,0-6.87,0-69.81.01-139.62.03-209.43.01-2.31,0-4.71-.05-6.9-.66C2.96,101.02-.25,96.37.02,91.27c.28-5.34,4.14-9.79,9.52-10.69,1.95-.33,3.98-.23,5.98-.23,69.48,0,138.95.03,208.43.04,1.92,0,3.85,0,5.77,0,.29-.48.58-.95.87-1.43Z"/>
                                </g>
                            </svg>
                        </button>
                    </Link>
                </div>
               {/* )} */}
            </div>
        </div>
    )
}
export default ProjectCard